var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col lg-6 lg-offset-3" }, [
        _c("nav", [
          _c("ul", [
            _c("li", [
              _c(
                "button",
                { staticClass: "green", on: { click: _vm.runProject } },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/images/launcher-mansion.jpg"),
                    },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col lg-6 lg-offset-3" }, [
      _c("h3", [_vm._v("Current active project")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Haunted village exploration / Exorcise vengeful spirits with a camera."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }