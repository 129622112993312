var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "seamless" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Local project status")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Welcome to your local game project management page."),
          ]),
          _vm._v(" "),
          _vm.getPublishedLevels.length > 0
            ? _c("nav", [
                _c("ul", [
                  _c("li", [
                    _c(
                      "button",
                      {
                        staticClass: "green",
                        on: {
                          click: function ($event) {
                            return _vm.runProject()
                          },
                        },
                      },
                      [_vm._v("Run your game project")]
                    ),
                  ]),
                ]),
              ])
            : _c("nav", [_vm._m(0)]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col lg-12" },
          [
            _c("h4", [_vm._v("Published levels")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "These levels have been published from the Map Editor. They can be loaded in the Raycaster Game Engine.\n          If you modify one of these levels via the Map Editor, you'll have to publish it again."
              ),
            ]),
            _vm._v(" "),
            _vm.getPublishedLevels.length === 0
              ? _c("p", { staticStyle: { color: "#800" } }, [
                  _vm._v("No published level."),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.getPublishedLevels, function (l) {
              return _c("LevelThumbnail", {
                key: l.name,
                attrs: {
                  name: l.name,
                  date: l.date,
                  preview: l.preview,
                  exported: l.published,
                  invault: l.invault,
                },
                on: { unpublish: ({ name }) => _vm.unpublish(name) },
              })
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col lg-12" },
          [
            _c("h4", [_vm._v("In vault levels")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                'These levels can be edited via the Map editor, but are still unavailable for the Game Engine until they are\n          published.\n          To publish a level, click on "Publish" or use the Map Editor.'
              ),
            ]),
            _vm._v(" "),
            _vm.getUnpublishedLevels.length === 0
              ? _c("p", { staticStyle: { color: "#800" } }, [
                  _vm._v("Nothing to publish."),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.getUnpublishedLevels, function (l) {
              return _c("LevelThumbnail", {
                key: l.name,
                attrs: {
                  name: l.name,
                  date: l.date,
                  preview: l.preview,
                  exported: l.published,
                  invault: l.invault,
                  publishable: true,
                },
                on: { publish: ({ name }) => _vm.publish(name) },
              })
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("button", { staticClass: "disabled" }, [
          _vm._v("Run your game project"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticStyle: { "padding-left": "1em" } }, [
        _c("span", { staticClass: "note" }, [
          _vm._v("(The game project need at list one published level)"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }