var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col lg-12" }, [
        _c("p", [
          _vm._v(
            "The Map Editor is a web software that allows you to design your own maps for a Raycaster Game Project."
          ),
        ]),
        _vm._v(" "),
        _c("nav", [
          _c("ul", [
            _c("li", [
              _c(
                "button",
                {
                  staticClass: "green",
                  on: {
                    click: function ($event) {
                      return _vm.openMapEditor()
                    },
                  },
                },
                [_vm._v("Open Map Editor")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col lg-12" }, [
        _c("h3", [_vm._v("The map editor")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }