var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col lg-3 md-4 sm-6" },
        [
          _c(
            "Logo",
            {
              attrs: {
                href: "https://www.jetbrains.com/?from=javascript-raycasting-engine-raphael-marandet",
                title: "Fast and powerful IDE that integrates Vue.js perfectly",
                caption: "Webstorm IDE",
              },
            },
            [
              _c("img", {
                attrs: {
                  alt: "logo",
                  src: require("../../../assets/logos/logo-webstorm.svg"),
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col lg-3 md-4 sm-6" },
        [
          _c(
            "Logo",
            {
              attrs: {
                href: "https://www.jetbrains.com/?from=javascript-raycasting-engine-raphael-marandet",
                title:
                  "Creators of Webstorm, Phpstorm and many more IDE that are essential for large and modern projects.",
                caption: "Jetbrains",
              },
            },
            [
              _c("img", {
                attrs: {
                  alt: "logo",
                  src: require("../../../assets/logos/logo-jetbrains.svg"),
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col lg-3 md-4 sm-6" },
        [
          _c(
            "Logo",
            {
              attrs: {
                href: "https://vuejs.org/",
                title:
                  "A cutting edge framework which is actually very fun to work with. Everything is faster with Vue.js.",
                caption: "Vue.js",
              },
            },
            [
              _c("img", {
                attrs: {
                  alt: "logo",
                  src: require("../../../assets/logos/logo-vue-js.svg"),
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col lg-3 md-4 sm-6" },
        [
          _c(
            "Logo",
            {
              attrs: {
                href: "https://nodejs.org/",
                title:
                  "A JavaScript runtime built on Chrome's V8 JavaScript engine.",
                caption: "Node.js",
              },
            },
            [
              _c("img", {
                attrs: {
                  alt: "logo",
                  src: require("../../../assets/logos/logo-node-js-2.svg"),
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col lg-3 md-4 sm-6" },
        [
          _c(
            "Logo",
            {
              attrs: {
                href: "https://webpack.js.org/",
                title:
                  "A module blunder. Essential when you write thousand of classes, files and assets.",
                caption: "Webpack",
              },
            },
            [
              _c("img", {
                attrs: {
                  alt: "logo",
                  src: require("../../../assets/logos/logo-webpack.svg"),
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col lg-3 md-4 sm-6" },
        [
          _c(
            "Logo",
            {
              attrs: {
                href: "https://jasmine.github.io/",
                title: "I do test driven development !",
                caption: "Jasmine",
              },
            },
            [
              _c("img", {
                attrs: {
                  alt: "logo",
                  src: require("../../../assets/logos/logo-jasmine.svg"),
                },
              }),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col lg-12" }, [
        _c("h3", [_vm._v("About the O876 Raycaster Game Engine")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "The O876 Raycaster Game Engine is an open-source, free-to-use framework, for building web browser games base on raycasting technology."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "This project has been developped with some useful products and libraries."
          ),
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("For game developers...")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n              The purpose of this site is only to show the engine features. If you want to actually create a game with this technology,\n              consider visiting the Project GitHub Repository located at :\n              "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/Laboralphy/o876-raycaster-engine",
              },
            },
            [_vm._v("https://github.com/Laboralphy/o876-raycaster-engine")]
          ),
          _vm._v(".\n            "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col lg-12" }, [
      _c("h3", [_vm._v("About the author...")]),
      _vm._v(" "),
      _c("p", [_vm._v("I spend too much time building games...")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }