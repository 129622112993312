var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-12" }, [
              _c("h3", [_vm._v("Basic Raycasting")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "The primary task of a Raycasting Engine is to render 3D simplified environnement.\n                        It is said that Raycasting is a 2.5D algorithm because the 3D environnement\n                        is rendered from a 2D map and there are only perpendicular walls only flat floor and\n                        ceiling, and so on... A lot of limitations that are not present in real 3D engines."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "But this simplicity has its own advantages :\n                    "
                ),
                _c("ul", [
                  _c("li", [_vm._v("fast level design.")]),
                  _vm._v(" "),
                  _c("li", [
                    _vm._v("No need of complex geometry understanding."),
                  ]),
                  _vm._v(" "),
                  _c("li", [_vm._v("runs on slow computers.")]),
                  _vm._v(" "),
                  _c("li", [_vm._v("fast assets downloading.")]),
                ]),
              ]),
              _c("p", [
                _vm._v(
                  "Let's have a look at what features are available in this engine..."
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-12" }, [
              _c("h3", [_vm._v("Door management")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "There are several type of doors, the only difference is the way they open :\n                        They can slide to any direction (left, right, top, bottom), there are even\n                        double panel sliding doors as well."
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-open-door-0.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Double panel door")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-open-door-1.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Top sliding door")]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-12" }, [
              _c("h3", [_vm._v("Secret door management")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Just like the game "),
                _c("i", [_vm._v("wolfenstein 3D")]),
                _vm._v(
                  ",\n                        walls can be pushed to reveal secret areas."
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-secret-door-0.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [
                      _vm._v(
                        "This wall has been pushed, and reveals a hidden zone !"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-12" }, [
              _c("h3", [_vm._v("Sprite management")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  'Animated sprites with composite effect (alpha, opacity...).\n                        Each sprite can have several overlays so rendering "haze" effects\n                        is possible.'
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/game-blight.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Sprites from Blight Magic")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/game-scr19.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Sprites from Blight Magic")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/game-scr20.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Sprites from Blight Magic")]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-12" }, [
              _c("h3", [_vm._v("Floor and ceiling textures")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Floor and ceiling can have textures, just like walls."),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/game-scr13.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [
                      _vm._v("Without floor and ceiling textures"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/game-scr18.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [
                      _vm._v("With floor and ceiling textures (looks better)"),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-12" }, [
              _c("h3", [_vm._v("Outdoor environnement / sky texture")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Wall, ceiling, and floor textures can be replaced by a sky texture."
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-sky-0.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Sky is visible outside")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-sky-1.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Red sky")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-sky-2.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [
                      _vm._v("Sky is visible through the ceilling"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-sky-3.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Dark sky and landscape")]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-12" }, [
              _c("h3", [_vm._v("Animated wall textures")]),
              _vm._v(" "),
              _c("p", [_vm._v("Walls can have animated textures.")]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-anim-wall-0.gif"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Fireplace")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-anim-wall-1.gif"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Pixelated fireplace")]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-12" }, [
              _c("h3", [_vm._v("Dynamic texture modification")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Wall, floor and ceiling textures can be modified,\n                        allowing programmer to draw anything on it (like drawing on a canvas and using canvas\n                        context methods). This ability is used for static lighting (lighten walls that are\n                        near light emmiting sources)."
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-decal-0.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("Ketchup on the wall !")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-light-effects-1.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [
                      _vm._v("Candles may affect wall textures"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-light-effects-0.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [
                      _vm._v(
                        "Light emmitting objects cast light and shadows on both floor and ceiling"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-12" }, [
              _c("h3", [_vm._v("Using VR Glasses")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "The framework has the ability to switch between normal rendering\n                        and "
                ),
                _c("b", [_vm._v("VR Rendering")]),
                _vm._v("."),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("You should try it out with "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.google.fr/search?q=vr+glasses+cardboard",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        this kind of cardboard glasses"
                    ),
                  ]
                ),
                _vm._v("."),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col lg-6 lg-offset-3 sm-8 sm-offset-2 xs-12 xs-offset-0",
                  },
                  [
                    _c("figure", [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/images/vr-glasses.jpg"),
                        },
                      }),
                      _vm._v(" "),
                      _c("figcaption", [_vm._v("VR Glasses")]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-vr-00.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("city : normal")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-vr-01.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("city : vr-rendering")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-vr-10.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("mansion : normal")]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col 4" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/images/feat-vr-11.jpg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("figcaption", [_vm._v("mansion : vr-rendering")]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }