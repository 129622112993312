var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pad" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "Card",
          { attrs: { color: "blue", title: "O876 Raycaster Game Engine" } },
          [
            _c("nav", [
              _c("ul", [
                _c("li", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/")
                        },
                      },
                    },
                    [_vm._v("Home")]
                  ),
                ]),
                _vm._v(" "),
                _vm.editorStatus
                  ? _c("li", [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/pm")
                            },
                          },
                        },
                        [_vm._v("Project manager")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.editorStatus
                  ? _c("li", [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/mapedit")
                            },
                          },
                        },
                        [_vm._v("Map editor")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/tech")
                        },
                      },
                    },
                    [_vm._v("Docs")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/demos")
                        },
                      },
                    },
                    [_vm._v("Demos")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/about")
                        },
                      },
                    },
                    [_vm._v("About")]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("router-view"),
            _vm._v(" "),
            _c("hr", { staticClass: "footpage-copyright" }),
            _vm._v(" "),
            _c("div", { staticClass: "footpage-copyright" }, [
              _vm._v("Opensource project created by "),
              _c("b", [_vm._v("Laboralphy")]),
              _vm._v("."),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }