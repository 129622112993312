var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("figure", { class: _vm.getComputedClass }, [
    _c("img", { attrs: { alt: _vm.name + " preview", src: _vm.getSource } }),
    _vm._v(" "),
    _c("figcaption", [
      _c("div", [
        _c("span", { staticClass: "filename" }, [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" - "),
        _c("span", { staticClass: "datestring" }, [
          _vm._v(_vm._s(_vm.getDateString)),
        ]),
      ]),
      _vm._v(" "),
      _vm.exported
        ? _c("div", [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.unpublish(_vm.name)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.invault ? "Unpublish" : "Delete"))]
            ),
          ])
        : _vm.publishable
        ? _c("div", [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.publish(_vm.name)
                  },
                },
              },
              [_vm._v("Publish")]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }