var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Making old-school games with Raycasting.")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "If you are interested in old game technologies like Raycasting, that were used during the early 90's,\n            and if you wonder how is it possible to build web games with such tech, then you are in the right place."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-8 xs-12" }, [
          _c("h3", [_vm._v("What is Raycasting ?")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Raycasting is a 3D rendering method using simple computations.\n                It renders 3D environments but with lots of limitation to ensure the highest frame rate.\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("This technology was mainly used in older 90's games like "),
            _c("b", [_vm._v("Wolfenstein 3D")]),
            _vm._v("\n                and "),
            _c("b", [_vm._v("Doom")]),
            _vm._v(
              ". Games built around this rendering method are easily designed because they are\n                essentially build out of a 2D map."
            ),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h3", [_vm._v("Main features")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [_vm._v("The engine is 100% javascript")]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Supported by any serious HTML 5 Browsers like Firefox, Google Chrome and Chromium."
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Fair performances : games will run at around 25 fps on older architectures like Intel Core2 Duo."
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Full screen, Pointer lock, and Audio support (mp3 and ogg)."
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "An HTML 5 Level Editor is available online - Designing levels is now easy."
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col lg-4 xs-12" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                alt: "raycasting explained",
                src: require("../../../assets/images/fig-raycasting.png"),
              },
            }),
            _vm._v(" "),
            _c("figcaption", [_vm._v("How raycasting works.")]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("What does it look like ?")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/game-ambush.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [
                  _vm._v("Reikaster underland (ambush in forest)"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/game-underwater.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Reikaster underland (underwater)")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/game-mansion-1.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("The mansion")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/game-mansion-7.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("The mansion (interior)")]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Inspirations")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "The Engine use the very same technics used by old 90's first person shooter games. The most popular in this genre was "
            ),
            _c("b", [_vm._v("Wolfenstein 3D")]),
            _vm._v("."),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col lg-offset-4 md-6 md-offset-3 sm-12 sm-offset-0",
              },
              [
                _c("figure", [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/images/wolf3d.jpg"),
                    },
                  }),
                  _vm._v(" "),
                  _c("figcaption", [_vm._v("Wolfenstein 3D by Id Sofware")]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Basic Raycasting")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "The primary task of a Raycasting Engine is to render 3D simplified environnement.\n                It is said that Raycasting is a 2.5D algorithm because the 3D environnement\n                is rendered from a 2D map and there are only perpendicular walls only flat floor and\n                ceiling, and so on... A lot of limitations that are not present in real 3D engines."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "But this simplicity has its own advantages :\n            "
            ),
            _c("ul", [
              _c("li", [_vm._v("fast level design.")]),
              _vm._v(" "),
              _c("li", [_vm._v("No need of complex geometry understanding.")]),
              _vm._v(" "),
              _c("li", [_vm._v("runs on slow computers.")]),
              _vm._v(" "),
              _c("li", [_vm._v("fast assets downloading.")]),
            ]),
          ]),
          _c("p", [
            _vm._v(
              "Let's have a look at what features are available in this engine..."
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Door management")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "There are several type of doors, the only difference is the way they open :\n                They can slide to any direction (left, right, top, bottom), there are even\n                double panel sliding doors as well."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-open-door-0.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Double panel door")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-open-door-1.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Top sliding door")]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Secret door management")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Just like the game "),
            _c("i", [_vm._v("wolfenstein 3D")]),
            _vm._v(
              ",\n                walls can be pushed to reveal secret areas."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-secret-door-0.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [
                  _vm._v(
                    "This wall has been pushed, and reveals a hidden zone !"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Sprite management")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              'Animated sprites with composite effect (alpha, opacity...).\n                Each sprite can have several overlays so rendering "haze" effects\n                is possible.'
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/game-blight.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Sprites from Blight Magic")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/game-scr19.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Sprites from Blight Magic")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/game-scr20.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Sprites from Blight Magic")]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Floor and ceiling textures")]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Floor and ceiling can have textures, just like walls."),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/game-scr13.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [
                  _vm._v("Without floor and ceiling textures"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/game-scr18.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [
                  _vm._v("With floor and ceiling textures (looks better)"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Outdoor environnement / sky texture")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Wall, ceiling, and floor textures can be replaced by a sky texture."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-sky-0.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Sky is visible outside")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-sky-1.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Red sky")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-sky-2.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [
                  _vm._v("Sky is visible through the ceilling"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-sky-3.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Dark sky and landscape")]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Animated wall textures")]),
          _vm._v(" "),
          _c("p", [_vm._v("Walls can have animated textures.")]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-anim-wall-0.gif"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Fireplace")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-anim-wall-1.gif"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Pixelated fireplace")]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Dynamic texture modification")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Wall, floor and ceiling textures can be modified,\n                allowing programmer to draw anything on it (like drawing on a canvas and using canvas\n                context methods). This ability is used for static lighting (lighten walls that are\n                near light emmiting sources)."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-decal-0.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Ketchup on the wall !")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-light-effects-1.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("Candles may affect wall textures")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col lg-4 sm-6 xs-12" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-light-effects-0.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [
                  _vm._v(
                    "Light emmitting objects cast light and shadows on both floor and ceiling"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col lg-12" }, [
          _c("h3", [_vm._v("Using VR Glasses")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "The framework has the ability to switch between normal rendering\n                and "
            ),
            _c("b", [_vm._v("VR Rendering")]),
            _vm._v("."),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("You should try it out with "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.google.fr/search?q=vr+glasses+cardboard",
                },
              },
              [_vm._v("\n                this kind of cardboard glasses")]
            ),
            _vm._v("."),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col lg-6 lg-offset-3 sm-8 sm-offset-2 xs-12 xs-offset-0",
              },
              [
                _c("figure", [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/images/vr-glasses.jpg"),
                    },
                  }),
                  _vm._v(" "),
                  _c("figcaption", [_vm._v("VR Glasses")]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-vr-00.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("city : normal")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-vr-01.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("city : vr-rendering")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-vr-10.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("mansion : normal")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col 4" }, [
              _c("figure", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/feat-vr-11.jpg"),
                  },
                }),
                _vm._v(" "),
                _c("figcaption", [_vm._v("mansion : vr-rendering")]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }