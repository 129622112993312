var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a", { attrs: { href: _vm.href } }, [
    _c("figure", { staticClass: "logo" }, [
      _c("div", { staticClass: "logo-container" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("figcaption", [_vm._v(_vm._s(_vm.caption))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }