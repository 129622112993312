var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm.title.length > 0
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col lg-12" }, [
            _c("h2", { class: "title " + _vm.color }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col lg-12" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }